import { tailwind } from "@theme-ui/presets"

const customStyles = {
    chip: {
        display: 'inline-flex',
        flexDirection: 'row',
        backgroundColor: '#e5e5e5',
        color: tailwind.colors.purple[7],
        border: 'none',
        cursor: 'default',
        height: '50px',
        outline: 'none',
        fontSize: '20px',
        whiteSpace: 'nowrap',
        alignItems: 'center',
        borderRadius: '16px',
        verticalAlign: 'middle',
        textDecoration: 'none',
        justifyContent: 'center',
        margin: '10px',
    },
    chipContent: {
        cursor: 'inherit',
        display: 'flex',
        alignItems: 'center',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        paddingLeft: '12px',
        paddingRight: '12px',
    }
}

export default customStyles;
