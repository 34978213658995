/** @jsx jsx */
import { jsx } from "theme-ui"
import  { Link } from "gatsby"
import customStyles from "./customStyles"

const listOfTags = [
    {
        name: 'View All Posts',
        link: '/blog'
    },
    {
        name: 'Tech Tutorials',
        link: '/tags/technical'
    },
    {
        name: 'Finance',
        link: '/tags/finance'
    },
    {
        name: 'Business',
        link: '/tags/business'
    }
];

const SubNavigator = () => (
    <div>
        <section>
            {listOfTags.map((tag) => (
                <p sx={customStyles.chip}><p sx={customStyles.chipContent}><Link sx={(t) => ({ ...t.styles?.a })} to={tag.link}>{tag.name}</Link></p></p>
            ))}
        </section>
    </div>
)

export default SubNavigator